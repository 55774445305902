<template>
    <div class="flfwindex">
        <div class="topbg"></div>
        <div class="qxxwslx base_w">
          <p class="qxxwslx1">请选择文书类型标签</p>
          <div class="qxxwslx2 flexbox">
            <span v-for="(item,i) in labellist" :key="i" @click.stop="change(i,item)" :class="[spanItem == i ? 'active' : '']">{{item.name}}</span>
          </div>
          <div class="qxxwslx3 flexbox">
            <div>
              <img src="@/assets/img/fw1.png" alt="">
              服务保障
            </div>
            <div>
              <img src="@/assets/img/fw2.png" alt="">
              认证律师
            </div>
            <div>
              <img src="@/assets/img/fw3.png" alt="">
              急速响应
            </div>
          </div>
          <zixun :type="5" :wenshubiaoqian="wenshubiaoqian"></zixun>
        </div>
     
        <div class="why base_w"> </div>
        <div class="lc"> </div>
        <div class="bz base_w"> </div>
    </div>
</template>

<script>
import zixun from '@/components/zixun'

import apiUrl from '@/api/main'
export default {
    components: {zixun},
    data() {
        return {
          labellist:[],
          spanItem:0,
          wenshubiaoqian:'',
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getinfo(); // 获取法律文书标签
      // 跳转回顶部
      document.documentElement.scrollTop = 0;
    },
    methods: {
     // 跳转
     tourl(url) {
        this.$router.push({
          path: url
        })
      },
      // 获取法律文书标签
      getinfo() {
        apiUrl.lsFuwulabel().then((res) => {
          this.labellist = res.data;
          this.wenshubiaoqian = res.data[0].name;
        })
      },
      change(i,data){
        this.spanItem = i;
        this.wenshubiaoqian = data.name;
      },
    
    },
  
    }
</script>
<style lang='scss' scoped>

.flfwindex{
  .topbg{
    background: url('../../assets/img/bg9.png') no-repeat;
    background-size: 100% 100%;
    height: 320px;
  }
  .qxxwslx{
      min-height: 613px;
      background: #FFFFFF;
      box-shadow: 0px 7px 20px 0px rgba(34,42,53,0.08);
      border-radius: 10px;
      padding: 27px 33px 47px;
      // transform: translateY(-52px);
      margin-top: -62px;
      .qxxwslx1{
        font-size: 26px;
        font-weight: 500;
        color: #333333;
      }
      .qxxwslx2{
        flex-wrap: wrap ;
        span{
          margin-right: 24px;
          margin-top: 24px;
          border-radius: 18px;
          border: 1px solid #CCCCCC;
          font-size: 16px;
          color: #333333;
          padding: 9px 32px;
          cursor: pointer;
        }
        span:hover{
          color: #305BFE;
          border: 1px solid  #305BFE;
        }
        span.active{
          border: 1px solid  #305BFE;
          background-color: #305BFE;
          color: #fff;
        }
      }
      .qxxwslx3{
        margin-top: 48px;
        margin-bottom: 16px;
        div{
          margin-right: 40px;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        img{
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
  }
  .why{
    background: url('../../assets/img/bg10.png') no-repeat;
    background-size: 100% 100%;
    height: 360px;
    margin-top:80px;
    margin-bottom: 80px;
  }
  .lc{
    background: url('../../assets/img/bg11.png') no-repeat;
    background-size: 100% 100%;
    height: 724px;
    margin-bottom: 80px;
  }
  .bz{
    background: url('../../assets/img/bg12.png') no-repeat;
    background-size: 100% 100%;
    height: 488px;
    margin-bottom: 80px;
  }




























}
</style>